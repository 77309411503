<template lang="pug">
v-app
  v-navigation-drawer(
    app,
    clipped,
    left,
    mobile-breakpoint="0",
    v-model="drawer"
  )
    v-list-item
      v-list-item-content
        v-list-item-title.text-h6 {{ greeting }}
        v-list-item-subtitle Let's learn Technical English 😎
    v-list
      v-list-item(
        @click="[umami(`Open trainer`), (drawer = false)]",
        to="/trainer"
      )
        v-list-item-action
          v-icon mdi-human-male-board
        v-list-item-content
          v-list-item-title Vocab Trainer
      v-divider
      v-list-item(
        @click="[umami(`Open about`), (drawer = false)]",
        to="/about"
      )
        v-list-item-action
          v-icon mdi-help-circle-outline
        v-list-item-content
          v-list-item-title About
      v-divider
      v-list-item(
        @click="[umami(`Open imprint`), (drawer = false)]",
        to="/imprint"
      )
        v-list-item-action
          v-icon mdi-scale-balance
        v-list-item-content
          v-list-item-title Imprint / Privacy
      v-divider
  v-app-bar(
    app,
    clipped-left,
    color="primary"
  )
    v-app-bar-nav-icon(
      @click.stop="umami(`Toggle drawer`) || (drawer = !drawer)",
      title="Open App Menu"
    )
    v-toolbar-title.pl-1.pb-1 pester pac
      //- v-flex.d-none.d-sm-flex.mr-3.ml-1
      //-   img.polandball(
      //-     alt="Pester logo",
      //-     height="42px",
      //-     src="@/assets/pester-pac-automation.webp",
      //-     width="42px"
      //-   )
      //- v-flex(
      //-   :class="$vuetify.breakpoint.name === `xs` ? `mb-0` : `mt-1`"
      //- )
      //-   span.headline.font-weight-medium pester pac
  v-main(
    app
  )
    v-slide-y-reverse-transition(
      mode="out-in"
    )
      router-view(
        :ready="ready",
        v-if="ready"
      )
  v-footer(
    app
  )
    span.caption
      | Built with ❤ in Hamburg by&nbsp;
      a.font-weight-medium(
        @click="openLink",
        href="https://www.detroit-english.de/?utm_source=pester&utm_medium=referral",
        rel="noopener",
        style="text-decoration: none",
        target="_blank"
      ) Detroit English
</template>

<script>
import '@/styles/custom.css'

export default {
  data: () => ({
    drawer: false,
    ready: false,
    greeting: `Welcome!`,
  }),

  async created() {
    await import(/* webpackChunkName: "flashcard" */ `@/assets/pouchdb.js`)

    let db = await this.$bucket.db

    if (!PROD) window.db = db

    let dbHash = localStorage.getItem(`dbHash`)

    const hashDiff = dbHash !== DB_HASH
    if (hashDiff || !dbHash) {
      if (hashDiff) {
        localStorage.removeItem(`count`)
        db = await this.$bucket.nuke()
      }
      let { default: docs } = await import(
        /* webpackChunkName: "trainer" */ `@/assets/pester.js`
      )
      await db.bulkDocs({ docs }).catch(console.error.bind(console))

      localStorage.setItem(
        `dbHash`,
        await db
          .get(`hash`)
          .then(doc => doc.hash)
          .catch(err => (console.error(err), `0`))
      )
    }
    this.ready = true
  },
  methods: {
    umami: window.umami || console.log,
    openLink() {
      umami(`Open DTE website`)
    },
  },
}
// We just need these values in here for building custom icons font
// component icon: mdi-checkbox-blank-outline
// component icon: mdi-checkbox-marked
// component icon: mdi-chevron-left
// component icon: mdi-chevron-right
// component icon: mdi-arrow-up
// component icon: mdi-arrow-down
// component icon: mdi-menu
</script>
